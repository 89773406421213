import React from 'react';
import '../App.css';
import NewQuote from './NewQuote';

function App() {
  return (
    <NewQuote />
  );
}

export default App;
